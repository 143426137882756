var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Weapon Stats")]),
      _c("b-table", {
        attrs: {
          items: _vm.updated_data,
          fields: _vm.fields,
          "sort-by": "picks",
          "sort-desc": true,
          hover: "",
          striped: "",
          dark: ""
        },
        scopedSlots: _vm._u([
          {
            key: "thead-top",
            fn: function() {
              return [
                _c(
                  "b-tr",
                  [
                    _c(
                      "b-th",
                      { attrs: { colspan: _vm.show_pick_percentage ? 4 : 3 } },
                      [_vm._v(" Weapon Picks ")]
                    ),
                    _c("b-th", { attrs: { colspan: "2" } }, [
                      _vm._v(" Kill Breakdown ")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "cell(weapon)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("img", {
                    attrs: {
                      src: field.item.weapon_image_url,
                      alt: field.item.weapon_name
                    }
                  }),
                  _vm._v(" " + _vm._s(field.item.weapon_name) + " ")
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }